
// import App from "./App"

import AddClients from "./AddClients"
import AddEvents from "./AddEvents"
import AddPhotos from "./AddPhotos"
import ManageClients from "./ManageClients"
import ManageEvents from "./ManageEvents"
import MyDashboard from "./MyDashboard"
import UploadPhotos from "./UploadPhotos"
import ViewPhotos from "./ViewPhotos"

const organisms = {
    
    MyDashboard,
    ManageEvents,
    AddClients,
    ManageClients,
    AddEvents,
    UploadPhotos,
    ViewPhotos,
    AddPhotos
    
}
export default organisms