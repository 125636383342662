import React, { useState, useEffect } from 'react'
import organisms from '../../organism'
import service from '../../api/services'
// import { getDropdown, getUniqueObj } from '../../../constants/utils'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../constants/constant'
const { AddClients } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const params: any = useParams()
    const photoId = params.id
    const navigate = useNavigate()

    const [buttonClick, setButtonClick] = useState(false)
    const [clientData, setClientData] = useState<any>({
        name: '',
        email: '',
        mobile: '',
        address: '',
        // password:'',

    })



    const submitClient = async () => {
        console.log("clientdata")
        let payloadOne = {
            name: clientData.name,
            email: clientData.email,
            mobile: clientData.mobile,
            address: clientData.address,
            // password:clientData.password
        }

        let payload = clientData?
            { ...payloadOne } : payloadOne
        try {
            let response
            if (params.id) {
                response = await service.ManageClients.edit(params.id, payload)
            } else {
                response = await service.ManageClients.add(payload)
            }
            if (response.status === 201 || response.status === 200) {
                addToast("Submit Successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {

        if (buttonClick) {
            submitClient()
            setButtonClick(false)
        }
    }, [buttonClick])

    useEffect(() => {
        console.log("photoId",photoId,params.id)
        if (params.id && !buttonClick) {
            getDatabyId()
        }
    }, [])

    const getDatabyId = async () => {
        try {
            let response = await service.ManageClients.getDetailById(params?.id)
            if (response.status === 200) {
                handleEditResponse(response.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEditResponse = (response: any) => {
        console.log("response" ,response)
        setClientData({
            name: response.name,
            email: response.email,
            mobile: response.mobile,
            address: response.address,
            // password:response.password,

        })
    }

    return (
        <>
            <AddClients
                clientData={clientData}
                setClientData={setClientData}
                setButtonClick={setButtonClick}
            />
        </>
    )
}

export default Index



