import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { manageevents } = Dictionary

const ManageEvents = {
  view: async (paramsObj:any) => {
    return axios.get(BASE_URL + manageevents.events(),{ params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + manageevents.addEvents(), data)
  },
  status: async (id: string) => {
    return axios.put(BASE_URL + manageevents.EventsById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + manageevents.EventsById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + manageevents.EventsById(id))
  },

}
export default ManageEvents
