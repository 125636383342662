  
  export const validationRulesTwo: any = {
    event_date: {
      required: true,
    },
      name: {
        required: true,
      },
      event_name: {
        required: true,
      },
      event_detail: {
        required: true,
      },
      // client_id:{
      //   required:true
      // }
   
}