import React, { useEffect, useState } from "react";
import Wrapper from "../../layout/Wrapper/wrapper";
import Card from "../../layout/Card/card";
import atoms from "../../components/atoms";
// import constant from "../../constants/constant";
import { validationRulesTwo } from "./validate";
import { useParams } from "react-router-dom";
import "../../molecules/ManageEvents/manage.scss";
import Select from "../../components/atoms/Select";
import './events.scss'

const { Input, Button } = atoms;
const Index = ({
  photoData,
  eventData,
  setEventData,
  setButtonClick,
  eventsList
}: any) => {
  const params: any = useParams();
  const editId = params?._id
  // const inputRef = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState<any>({});


  const handleChange = (name: string, value: any) => {
    console.log("value==>>", name, value);

    setEventData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

 
  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRulesTwo) {
      if (validationRulesTwo.hasOwnProperty(field)) {
        if (validationRulesTwo[field]?.required && !eventData[field]) {
          newErrors[field] = "This field is required.";
        } else if (
          validationRulesTwo[field]?.label?.required &&
          !eventData[field]?.label
        ) {
          newErrors[field] = "Please Select field";
        }
        // if (!clientData.password && !params.id) {
        //   newErrors.password = "This field is required.";
        // }
      }
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = validate();
    console.log("isVa...", isValid, errors);
    if (isValid) {
      setButtonClick(true);
    } else {
      setButtonClick(false);
    }
  };
  console.log("eventsList", eventsList)
  useEffect(() => {
    console.log("eventData", eventsList)
  }, [eventData, photoData])


  return (
    <>
      <Wrapper>
        <Card>
          <div className="row align-items-center mb-4">
          <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 text-start mb-3">
  <h4 className="mb-0 textnew fw-600">
    {editId ? "Update" : "Create"} Events
  </h4>
</div>
            {editId && <div className="col-6 text-end ">
              {/* <Button>Change Password</Button> */}
            </div>}
          </div>

          <div className="row mt-3">
            <div className="col-6 text-start">
         
              <Select
                label={"Client Name"}
                options={eventsList}
                className={"selecttype"}
                placeholder={"Select Client"}
                value={eventData.name}
                onChange={(e: any) => {
                  handleChange("name", e);
                }}
                errors={errors.name}
              />
           
              {/* <Input
                errors={errors.clientname}
                label={"Client Name"}
                type={"text"}
                placeholder={"Enter Client Name"}
                value={eventData?.clientname}
                onChange={(e: any) => {
                  handleChange("client_name", e.target.value);
                }}
              /> */}
            </div>
            <div className="col-6">
              <Input
                errors={errors.event_name}
                label={"Event Name"}
                type={"text"}
                placeholder={"Enter Event Name"}
                value={eventData?.event_name}
                onChange={(e: any) => {
                  handleChange("event_name", e.target.value);
                }}
              />
            </div>

            <div className="col-6 mt-3">
              <Input
              className="date-input"
                errors={errors.event_date}
                label={"Event Date"}
                type={"date"}
                placeholder={"Enter Event Date "}
                value={eventData?.event_date}
                onChange={(e: any) => {
                  handleChange("event_date", e.target.value);
                }}
              />
            </div>
            <div className="col-6 mt-3">
              <Input
                errors={errors.event_detail}
                label={"Event Details"}
                type={"text"}
                placeholder={"Enter Event Details"}
                value={eventData?.event_detail}
                onChange={(e: any) => {
                  handleChange("event_detail", e.target.value);
                }}
              />
            </div>
          </div>
         

          <div className="row mt-4">

          </div>
          {/* </div> */}
          <div className="justify-content-center d-flex mt-4">
            <Button
              name={`${params._id ? "Update" : "Create"} Events`}
              className={"w-25 m-4 p-2"}
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>

        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
