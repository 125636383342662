export const validationRules: any = {
  name: {
    required: true,
  },
  email: {
    required: true,
  },
  mobile: {
    required: true,
  },
  address: {
    required: true,
  },

}

  