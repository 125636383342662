import React, { useEffect, useState } from 'react'
// import image from '../../../assets/images/login-card.png'
import { Input, Label } from "reactstrap";
import './reset.scss'
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import services from '../../../api/services'
import { useParams } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from 'react-icons/fa';
const Index = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<any>({});
  //   const [username, setUsername] = useState('');
  const [resetpassword, setResetPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [isResetPasswordValid, setResetPasswordValid] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const params = useParams()


  const searchParams = new URLSearchParams(window.location.search);

  // Get the 'token' parameter from the URL
  const token = searchParams.get('token');
  console.log(token);
  //   const handleUsernameChange = (e: any) => {
  //     setUsername(e.target.value);
  //     setUsernameValid(true);
  //   };
  console.log(JSON.stringify(params.token))
  const handleResetPasswordChange = (e: any) => {
    setResetPassword(e.target.value);
    setResetPasswordValid(true);
  };

  const handlePasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordValid(true);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;

    // if (username.trim() === '') {
    //   setUsernameValid(false);
    //   isValid = false;
    // }
    if (resetpassword.trim() === '') {
      setResetPasswordValid(false);
      isValid = false;
    }
    if (confirmpassword.trim() === '') {
      setConfirmPasswordValid(false);
      isValid = false;
    }

    if (isValid) {


      let payload = {
        // "email": username,
        "password": resetpassword,
        "confirm_password": confirmpassword,
        "token": token
      }

      try {
        let response = await services.auth.resetPassword(payload,)
        if (response?.status === 200) {
          localStorage.setItem("passwordResponse", JSON.stringify(response.data.data))
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          localStorage.setItem("accessToken", response?.data?.data?.accessToken)
          navigate("/resetpassword")
        }
      } catch (error: any) {
        console.log(error)
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }

    }
  };
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='login-card-parent d-flex align-items-center justify-content-center'>

            <div className='login-card' >
              <div className='row'>
                <div className='col-md-6 '>
                  <form onSubmit={handleSubmit}>
                    <div className="">  <h2 className='d-flex mx-5 para-login mt-5'>Reset Password</h2>
                    </div>


                    <div className="">
                      {/* <div className=""><Label className="d-flex form-label email mt-5 mx-5 ">Email Address</Label>
                        <Input type={"email"}
                          errors={errors.email}
                          className="form-control py-3 mx-5" placeholder="Enter your email address"
                          onChange={handleUsernameChange} label={"Email address"}
                          defaultValue={username}
                        />
                        {!isUsernameValid && <p className="error-text">Email is required</p>}
                      </div> */}
                      <div className="mt-4 mx-5">
                        <div className="mt-4">
                          <div className=""> <Label className="d-flex pasword">Password</Label>
                            <div className='d-flex justify-content-end'> <button onClick={() => setPasswordShow(!passwordShow)}
                              className="btn bg-transparent eyebtn mt-2 mx-2"
                              style={{ border: "none", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                              {passwordShow ? <MdOutlineRemoveRedEye /> : <FaRegEyeSlash />}
                            </button>
                            </div>
                            <Input onChange={handleResetPasswordChange}
                              type={passwordShow ? "text" : "password"}
                              errors={errors.resetpassword}
                              className='e-pas w-100'
                              name="new password"
                              placeholder="Enter New Password"
                              defaultValue={resetpassword} />
                            {!isResetPasswordValid && <p className="error-text">Password is required</p>}
                          </div>
                        </div>

                        {/* <div className="mt-4 confirmnew"> <Label className="d-flex confirmpasword"> Confrim New Password</Label>
                          <Input type={"text"} onChange={handlePasswordChange}
                            errors={errors.confirmpassword}
                            className='e-pas'
                            name="confirmpassword"
                            placeholder="Enter New Password"
                            defaultValue={confirmpassword} />
                          {!isConfirmPasswordValid && <p className="error-text"> Confirm Password is required</p>}
                        </div> */}

                        <div className="mt-4">
                          <div className=""> <Label className="d-flex pasword">Confirm Password</Label>
                            <div className='d-flex justify-content-end'> <button onClick={() => setPasswordShow(!passwordShow)}
                              className="btn bg-transparent eyebtn mt-2 mx-2"
                              style={{ border: "none", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                              {passwordShow ? <MdOutlineRemoveRedEye /> : <FaRegEyeSlash />}
                            </button>
                            </div>
                            <Input onChange={handlePasswordChange}
                              type={passwordShow ? "text" : "password"}
                              errors={errors.confirmpassword}
                              className='e-pas w-100'
                              name="confirmpassword"
                              placeholder="Confirm New Password"

                              defaultValue={confirmpassword} />
                            {!isResetPasswordValid && <p className="error-text">Password is required</p>}
                          </div>
                        </div>

                        <button className="reset-btn mt-4 py-2 px-5" type={"submit"}>Reset Password</button>
                      </div>
                    </div>





                  </form>
                </div>

                <div className="col-md-6">
                  <div className='login-image'></div>

                  {/* <img src={image} className='image-login' alt="image"/>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default Index
