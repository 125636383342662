import React, { useState, useEffect } from 'react'
import organisms from '../../organism'
import service from '../../api/services'
// import { getDropdown, getUniqueObj } from '../../../constants/utils'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../constants/constant'
const { AddEvents } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const params: any = useParams()
    const photoId = params.id
    const navigate = useNavigate()

    const [buttonClick, setButtonClick] = useState(false)
    const [eventData, setEventData] = useState<any>({

        name: '',
        event_date: '',
        event_detail: '',
        client_id: '',
        // client_name:'',
        
    })
    const [eventsList, setEventsList] = useState([])



    const submitClient = async () => {
        console.log("eventdata")
        let payloadOne = {
            name: eventData.name.label,
            event_date: eventData.event_date,
            event_detail: eventData.event_detail,
            client_id: eventData.name.value,
            client_name:eventData.client_name
            
        }

        let payload = eventData?
            { ...payloadOne } : payloadOne
        try {
            let response
            if (params.id) {
                response = await service.ManageEvents.edit(params.id, payload)
            } else {
                response = await service.ManageEvents.add(payload)
            }
            if (response.status === 201 || response.status === 200) {
                addToast("Submit Successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {

        if (buttonClick) {
            submitClient()
            setButtonClick(false)
        }
    }, [buttonClick])

    useEffect(() => {
        getAllClients()
        console.log("photoId", photoId, params.id)
        if (params.id && !buttonClick) {
            getDatabyId()
            getClientbyId()
        }
    }, [])

    const getAllClients = async () => {
        try {
            let response = await service.ManageClients.view({})
            getSelectData(response.data.data)
            console.log("responseresponse", response)
        } catch (error) {

        }
    }
    const getDatabyId = async () => {
        let temp = []
        try {

            let response = await service.ManageEvents.getDetailById(params?.id)
            if (response.status === 200) {
                eventsList && eventsList?.map((item:any,index)=>{
                    if(item?.value === response.data.data.client_id){
                        console.log("item==========>",item)
                    } 
                })
            }
        } catch (error) {
            console.log("EROOR", error);
        }
    };
    const getClientbyId = async () => {
        try {
            let response = await service.ManageClients.edit(params?.id)
            if (response.status === 200) {
                getSelectData(response.data.data)
                handleEditResponse(response.data.data)

            }
        } catch (error) {
            console.log(error);
        }
    }
    const getSelectData = (data: any) => {
        let temp: any = []
        data && data.map((item: any) => {
            temp.push({
                label: item.name,
                value: item._id,
                
            })
        })
        console.log("select data", temp, data)
        setEventsList(temp)
    }

    const handleEditResponse = (response: any) => {
        console.log("response2", response)
        setEventData({
            name: eventData?.name.value,
            event_detail: eventData[0]?.event_detail,
            event_date: eventData[0]?.event_date,
            client_id: eventData?.name.value,
            client_name: eventData[0]?.client_name,
        
        })
    }

    return (
        <>
            <AddEvents
                eventData={eventData}
                setEventData={setEventData}
                setButtonClick={setButtonClick}
                eventsList={eventsList}
            />
        </>
    )
}

export default Index



