import React, { useEffect, useState } from 'react'

// import atoms from '../../components/atoms'
import service from '../../api/services'
import organisms from '../../organism'
// const { Table, Button,  } = atoms
const { UploadPhotos } = organisms
const Index = () => {
  const [uploadList, setUploadList] = useState()
  const [search, setSearch] = useState('');
  // const[editPhotos,setEditPhotos] = useState();

  

  const getUploadList = async () => {
    try {
      let response = await service.UploadPhotos.view({"search": search })
      console.log("responseEvents",response);
      if (response?.status === 200) {
        setUploadList(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    getUploadList()
  }, [])

  // const handleStatus = async (id: string) => {
  //   try {
  //     let response = await service.UploadPhotos.edit(id);
  //     console.log('hiii status response',response)
  //     if (response.status === 200) {
  //       // addToast('Status updated successfully', {
  //       //   appearance: 'success',
  //       //   autoDismiss: true,
  //       //   autoDismissTimeout: 3000,
  //       // });
  //       getContactorList()
  //     }
  //   } catch (error) {

  //   }
  // }
  return (
    <>
      <UploadPhotos 
      //  setLimitPerPage={setLimitPerPage}
      //  setCurrentPage={setCurrentPage}
      uploadList={uploadList}
       setSearch={setSearch}
      />
    </>
  )
}

export default Index