import React, { useEffect, useState } from 'react'

// import atoms from '../../components/atoms'
import service from '../../api/services'
import organisms from '../../organism'
// const { Table, Button,  } = atoms
const { ManageClients } = organisms
const Index = () => {
    const [clientList, setClientList] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [selectedOption, setSelectedOption] = useState<{ value: number; label: string } | null>(null);

    const [search, setSearch] = useState('');


    const getClientList = async () => {
        try {
            let response = await service.ManageClients.view({ "page": currentPage, "search": search, "limit": limitPerPage, status:selectedOption?.value })
            console.log("responseSearch", response);
            if (response?.status === 200) {
                setClientList(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getClientList()
    }, [currentPage, search, limitPerPage,selectedOption])

    // const handleStatus = async (id: string) => {
    //   try {
    //     let response = await service.contractor.status(id);
    //     console.log('hiii status response',response)
    //     if (response.status === 200) {
    //       // addToast('Status updated successfully', {
    //       //   appearance: 'success',
    //       //   autoDismiss: true,
    //       //   autoDismissTimeout: 3000,
    //       // });
    //       getContactorList()
    //     }
    //   } catch (error) {

    //   }
    // }
    return (
        <>
            <ManageClients
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                clientList={clientList}
                setSearch={setSearch}
                setSelectedOption={setSelectedOption}
                
            />
        </>
    )
}

export default Index