import auth from './auth'
import dashboard from './dashboard'
import manageclients from './manageclients'
import manageevents from './manageevents'
import managephotos from './managephotos'

const dictionary = {
    auth,
dashboard,
manageevents,
manageclients,
managephotos
}
export default dictionary