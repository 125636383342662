import React, { useState } from 'react';
import molecules from '../../molecules';
import Wrapper from '../../layout/Wrapper/wrapper';
import TopBar from '../../layout/Topbar/topbar';
import Card from '../../layout/Card/card';
import atoms from '../../components/atoms'
import { useNavigate } from 'react-router-dom';
// import '../../molecules/ManageClients';
import './clients.scss'
import Select from 'react-select';
import SelectTop from '../../layout/SelectTop/SelectTop';
// import Select from '../../components/atoms/Select';


const { ManageClients } = molecules;
const { Search } = atoms;

const Index = ({ setLimitPerPage, setCurrentPage, setSearch, clientList, handleStatus, selectedOption, setSelectedOption }: any) => {
  const navigate = useNavigate();

  const options = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ];



  const handleChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
    console.log('Selected option:', selectedValue);
  };
 
  return (
    <>

      <Wrapper>

        <Card>
          <div className='row '>
            <div className='col-md-6 d-flex justify-content-between'>
              <Search
                searchCallBack={(e: string) => { setSearch(e) }}
              />

              <div>
                <Select
                  className='css-13cymwt-control css-1hb7zxy-IndicatorsContainer'
                  value={selectedOption}
                  onChange={handleChange}
                  options={options}
                  placeholder="Status"
                />


              </div>

            </div>
            <div className="col md-6">
              <TopBar
                heading={'ManageClients'}
                btnText={'Create Clients'}
                onClick={() => navigate('/clients/add')}
              />
            </div>
          </div>

          <ManageClients
            setLimitPerPage={setLimitPerPage}
            setCurrentPage={setCurrentPage}
            clientList={clientList}
            handleStatus={(id: string) => handleStatus(id)}
          />
        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
