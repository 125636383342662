import React from 'react';
import molecules from '../../molecules';
import Wrapper from '../../layout/Wrapper/wrapper';
import TopBar from '../../layout/Topbar/topbar';
import Card from '../../layout/Card/card';
import atoms from '../../components/atoms'
import { useNavigate,useParams } from 'react-router-dom';
// import { PiFunnelLight } from 'react-icons/pi';
import '../../molecules/ManageEvents/manage.scss';
// import './photos.scss'

const { ViewPhotos } = molecules;
const { Search } = atoms;

const Index = ({ setLimitPerPage, setCurrentPage, setSearch, photosList,eventData, handleStatus}: any) => {
const params = useParams()
    const navigate = useNavigate();
  console.log("photoslists")

  return (
    <>
      <Wrapper>
        
        <Card>
          <div className='row'>
            {/* <div className='w-50 col-md-6'>
              <Search
                searchCallBack={(e: string) => { setSearch(e) }}
              />
              
            </div> */}
            <div className="col md-6"> 
            <TopBar
          heading={'ViewPhotos'}
          btnText={'UploadPhotos'}
          onClick={() => navigate(`/viewphotos/${params.id}/add`)}
        />
            </div>
           </div>
        
          <ViewPhotos
            setLimitPerPage={setLimitPerPage}
            setCurrentPage={setCurrentPage}
            photosList={photosList}
            handleStatus={(id: string) => handleStatus(id)}
            eventData={eventData}
          />
        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
