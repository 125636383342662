import React from "react";
import Select from "react-select";
import './Select.scss'

export const ReactSelect = ({ className, placeholder,
    options, value,
    onChange, isMulti,
    menuIsOpen, isDisabled, defaultValue, label, htmlFor,errors
}: any) => {
    const handleChange = (option: any) => {
        onChange(option);
    };

    return (
        <>
            <div className='new-label'>
                {label && <label className='inputs-label d-flex' htmlFor={htmlFor}>{label}</label>}
                <Select
                    isDisabled={isDisabled}
                    menuIsOpen={menuIsOpen}
                    className={`multi_select mt-1 ${className}`}
                    // name={field.name}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    options={options}
                    isMulti={isMulti}
                    isSearchable={true}
                />
            </div>
            {errors && <span className="error-text">{errors}</span>}
        </>
    );
};

export default ReactSelect;
