import React, { useState, useEffect } from 'react'
import organisms from '../../organism'
import service from '../../api/services'
// import { getDropdown, getUniqueObj } from '../../../constants/utils'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../constants/constant'
const { AddPhotos } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const params: any = useParams()
    const photoId = params.id
    const navigate = useNavigate()

    const [buttonClick, setButtonClick] = useState(false)
    const [photoData, setPhotoData] = useState<any>({
        image_url: '',
        client_id: '',
        image_name: '',
        status: '',

    })



    const submitClient = async () => {
        console.log("clientdata")
        let payloadOne = {
            image_url: photoData. image_url,
            client_id: photoData.client_id,
            image_name: photoData.image_name,
            status: photoData.status,
            // password:clientData.password
        }

        let payload = photoData?
            { ...payloadOne } : payloadOne
        try {
            let response
            if (params.id) {
                response = await service.ManagePhotos.edit(params.id, payload)
            } else {
                response = await service.ManagePhotos.add(payload)
            }
            if (response.status === 201 || response.status === 200) {
                addToast("Submit Successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {

        if (buttonClick) {
            submitClient()
            setButtonClick(false)
        }
    }, [buttonClick])

    useEffect(() => {
        console.log("paramsId",params.id)
        if (params.id && !buttonClick) {
            getDatabyId()
        }
    }, [])

    const getDatabyId = async () => {
        try {
            let response = await service.ManagePhotos.getDetailById(params?.id)
            if (response.status === 200) {
                handleEditResponse(response.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEditResponse = (response: any) => {
        console.log("response" ,response)
        setPhotoData({
            image_url: response.image_url,
            client_id: response.client_id,
            image_name: response.image_name,
            status: response.status,
            // password:response.password,

        })
    }

    return (
        <>
            <AddPhotos
                photoData={photoData}
                setPhotoData={setPhotoData}
                setButtonClick={setButtonClick}
            />
        </>
    )
}

export default Index



