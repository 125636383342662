import React, { useEffect, useState, useRef } from "react";
import Wrapper from "../../layout/Wrapper/wrapper";
import Card from "../../layout/Card/card";
import atoms from "../../components/atoms";
import service from "../../api/services";
// import constant from "../../constants/constant";
// import { validationRulesTwo } from "./validate";
import { useParams } from "react-router-dom";

import './addPhotos.scss'

const { Input, Button } = atoms;
const Index = ({
    photoData, eventData, setEventData,
}: any) => {
    const params: any = useParams();
    const editId = params?.id
    // const inputRef = useRef<HTMLInputElement | null>(null);
    const [errors, setErrors] = useState<any>({});
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageUrl, setImageUrl] = useState<any>([]);
    const [imageArr, setImageArr] = useState<any>([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState('');

    const chooseFileToUpload = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const _handleImageChange = (e: any) => {
        if (e.target.files.length > 0) {
            let image_Url = URL.createObjectURL(e.target.files[0]);
            setImagePreview(image_Url);
            setImageUrl(e.target.files);
            console.log("e.target.files", e.target.files);
        }

    };




    const _handleSubmit = async () => {
        const formData = new FormData();
        console.log("Image URL", imageUrl.length, imageUrl[0])
        for (let i = 0; i < imageUrl.length; i++) {
            formData.append("photos", imageUrl[i]);
        }
        try {
            let response: any = await service.ManagePhotos.add(params.id, formData)
            console.log("Upload photos", response)
            getPhotos()
        } catch (error) {

        }
    };


    const handleChange = (name: string, value: any) => {
        console.log("value==>>", name, value);

        setEventData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    };

    const getPhotos = async () => {

        try {
            let response: any = await service.ManagePhotos.view(params.id)
            console.log("Upload photos", response)
            setImageArr(response.data.data)
        } catch (error) {

        }
    };

    useEffect(() => {
        setImageUrl(imageUrl);
        setImagePreview('');
        getPhotos();
    }, [imageUrl]);


    return (
        <>
            <Wrapper>
                <Card>
                    <div className="row align-items-center mb-4">
                        <div className="col-6 text-start ">
                            <h4 className="mb-0 textnew fw-600">
                                {editId ? "Upload" : "Upload"} Photos
                            </h4>
                        </div>
                        {/* {editId && <div className="col-6 text-end ">
              <Button>Change Password</Button>
            </div>} */}
                    </div>


                    <div className="my-0 py-0">
                        <div className="row justify-content-center">
                            <div className="col-11 p-0 mb-3">
                                <div className="d-flex justify-content-between align-items-center">

                                </div>
                            </div>
                            <div className="col-11">
                                <div className="row">
                                    <div className="col-4">
                                        <div style={{ marginTop: '24px' }}>
                                            <div>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}

                                                    />
                                                </div>
                                                <div className="avatarPreview cursor-pointer" onClick={chooseFileToUpload}>
                                                    <div
                                                        id="imagePreview"
                                                        style={{
                                                            backgroundImage: `url(${imagePreview})`,
                                                        }}
                                                        className={`backgroundimage upload-image ${imagePreview && 'addcustomimage'}`}
                                                    >
                                                        <p className="text-primary text-center">Add Image</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                name="carousel"
                                                type="file"
                                                ref={inputFileRef}
                                                accept="image/*"
                                                onChange={(e) => { _handleImageChange(e) }}
                                                multiple
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        <div className="text-center mt-2">
                                            <Button className="up-btn px-5" isDisabled={imageUrl ? false : true} onClick={_handleSubmit}>
                                        Upload
                                    </Button>
                                        </div>
                                    </div>
                                    {imageArr.map((item: any, index: number) => (
                                        <div key={item.id} className="col-4">
                                            <div className="imagePreview mt-4 mb-2">
                                                <img className="img-fluid" src={`https://photographer-api.codezilla.io/${item.image_url}`} alt={`Image ${index}`} />
                                                <div className="delete-icon" onClick={() => {
                                                    setSelectedImageId(item.id);
                                                    setDeleteConfirmation(true);
                                                }}>
                                                    <i className="dripicons-cross align-middle" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* </div> */}
                    <div className="justify-content-center d-flex mt-4">
                        {/* <div className="text-center mt-1">
                            <Button className="px-5 py-2 up-btn" isDisabled={imageUrl ? false : true} onClick={_handleSubmit}>
                                Upload
                            </Button>
                        </div> */}
                    </div>

                </Card>
            </Wrapper>
        </>
    );
};

export default Index;
