import React from 'react';
import molecules from '../../molecules';
import Wrapper from '../../layout/Wrapper/wrapper';
import TopBar from '../../layout/Topbar/topbar';
import Card from '../../layout/Card/card';
import atoms from '../../components/atoms'
import { useNavigate } from 'react-router-dom';
// import { PiFunnelLight } from 'react-icons/pi';
import '../../molecules/ManageEvents/manage.scss';
import './photos.scss'

const { ManageEvents } = molecules;
const { Search } = atoms;

const Index = ({ setLimitPerPage, setCurrentPage, setSearch, eventsList,eventData, handleStatus}: any) => {
  const navigate = useNavigate();
  console.log("eventslists")

  return (
    <>
      <Wrapper>
        
        <Card>
          <div className='row'>
            <div className='w-50 col-md-6'>
              <Search
                searchCallBack={(e: string) => { setSearch(e) }}
              />
              
            </div>
            <div className="col md-6"> 
            <TopBar
          heading={'ManageEvents'}
          btnText={'Create Events'}
          onClick={() => navigate('/events/add')}
        />
            </div>
           </div>
        
          <ManageEvents
            setLimitPerPage={setLimitPerPage}
            setCurrentPage={setCurrentPage}
            eventsList={eventsList}
            handleStatus={(_id: string) => handleStatus(_id)}
            eventData={eventData}
          />
        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
