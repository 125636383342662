import React, { useState } from "react";
// import { FiEye } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import constant from '../../constants/constant'
import atoms from '../../components/atoms'
import './manage.scss'
const { Table, } = atoms

const Index = ({ setLimitPerPage, setCurrentPage, eventsList, }: any) => {
    const navigate = useNavigate()
    console.log("event++++", eventsList)

    // let startIndex = (eventsList?.currentPage - 1)*eventsList?.limit
    const columns = [
        {
            name: 'S.No',
            selector: (row: any, index: number) => ( index + 1).toString().padStart(2, '0'), width: "50px",
        },
        {
            name: 'Client Name',
            selector: (row: any) => row?.client_name, minWidth: "10px"
        },
        {
            name: 'Event Name',
            selector: (row: any) => row?.name,
        },
        {
            name: 'Event Date',
            selector: (row: any) => row?.event_date,
        },
        {
            name: 'Photo Status',
            selector: (row: any) => row?.status
        },

        {
            name: 'Event Details',
            selector: (row: any) => row.action,
        },
        {
            name: 'Photo Details',
            selector: (row: any) => row.actionTwo,
        },
    ];
    return (
        <div>
            <div>
            {/* <>{console.log("eventsList?.limiteventsList?.limit",eventsList?.limit)}</> */}
                <Table
                    pagination={true}
                    paginationTotalRows={eventsList?.count}
                    setLimitPerPage={(e: number) => setLimitPerPage(e)}
                    setCurrentPage={(e: number) => setCurrentPage(e)}
                    columns={columns}
                    data={eventsList && eventsList
                        ?.map((item: any, index: number) => {
                            console.log("item: ", item.status)
                            return ({
                                ...item,
                                status: <div className={`${item.status === true ? "green-status" : "red-status"}`}>{item.status === true ? "Submitted" : "Not Submitted"}</div>,
                                isSubmitted: item.isSubmitted ? (
                                    <div className="green-status">
                                        <p className="color-green m-1 p-1">submitted</p>
                                    </div>) : <div className="red-status">
                                    <p className="color-red m-1 p-1">Not Submitted</p>
                                </div>,
                                action:

                                    <> <div className='table-data-width'>
                                        <button
                                            className="edit-btn pe-4 px-3 "
                                            onClick={() => navigate(`/events/edit/${item._id}`)}
                                        >
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </button>
                                    </div>

                                    </>,


                                actionTwo:

                                    <> <div className='table-data-width'>
                                        <>
                                            <button
                                                className="photos-btn pe-3 px-2 py-2"
                                                onClick={() => navigate(`/viewphotos/${item._id}`)}
                                            >
                                                <span className='ms-2 mb-0'>View Photos</span>
                                            </button>
                                        </>
                                    </div>

                                    </>



                                // ========================================================
                                // ...item,
                                // status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                // action:
                                //     <Link
                                //         to={`/contractors/edit/${item.id}`}
                                //     >
                                //         <Button className=" btnEdit table-edit-button fs-12">
                                //             <div className='d-flex align-items-center'>
                                //                 <FiEye />
                                //                 <span className='ms-2 mb-0'>View</span>
                                //             </div>
                                //         </Button>
                                //     </Link>





                            })
                        })
                    }
                />
            </div>
        </div>
    )
}

export default Index