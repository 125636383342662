import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs'

// import * as Yup from 'yup';
import { Spinner } from 'reactstrap';
import Backdrop from '@mui/material/Backdrop';
import service from '../../api/services';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import './viewphoto.scss'
import { useParams } from 'react-router-dom';
import PopupModel from '../../components/atoms/Popup';
import ProgressBar from '../../components/atoms/Popup/ProgressBar';

import LinearProgress from '@mui/material/LinearProgress';
const Index = ({ photosList }: any) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageUrl, setImageUrl] = useState<any>([]);
    const [imageArr, setImageArr] = useState<any>([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState('');
    const [loading, setLoading] = useState(true);
    const [isBlack, setIsBlack] = useState(false);
    const params = useParams()
    const chooseFileToUpload = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const handleImageLoad = () => {
        setLoading(false);

    };

    const _handleImageChange = (e: any) => {
        if (e.target.files.length > 0) {
            let image_Url = URL.createObjectURL(e.target.files[0]);
            setImagePreview(image_Url);
            setImageUrl(e.target.files);
            console.log("e.target.files", e.target.files);
        }

    };



    const _handleSubmit = async () => {
        const formData = new FormData();
        console.log("Image URL", imageUrl.length, imageUrl[0])
        for (let i = 0; i < imageUrl.length; i++) {
            formData.append("photos", imageUrl[i]);
        }
        try {
            let response: any = await service.ManagePhotos.add(params.id, formData)
            console.log("Viewphotos", response)
            getPhotos()
        } catch (error) {

        }
    };


    const getPhotos = async () => {
        try {
            let response: any = await service.ManagePhotos.view(params.id)
            console.log("managephotos", response)
            setImageArr(response.data.data)
        } catch (error) {

        }
    };
    const deletePhotos = async () => {
        console.log("deletePhotos", selectedImageId)
        let payload = {
            selected_ids: [selectedImageId]
        }
        try {
            let response: any = await service.ManagePhotos.deletePhotosById(params.id, payload)
            console.log("managephotos", response)
            getPhotos()
        } catch (error) {

        }
    };



    useEffect(() => {
        setImageUrl(imageUrl);
        setImagePreview('');
        getPhotos();

    }, [imageUrl]);

    return (
        <>
            <div className="my-0 py-0">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumb breadcrumbItem="Images List" />
                        </div>
                    </div>

                    <div className="row">
                        {imageArr && imageArr.map((item: any, index: number) => (
                            <div key={item._id} className="col-md-4 mt-4">
                                <div className="card card-imgs">
                             
                                    <div className="mt-4 mb-2">
                                   
                                    {loading && (
                                            <div className="backdrop py-5">
                                              {/* <ProgressBar variant="buffer" value={progress} valueBuffer={buffer}/> */}
                                                {/* <CircularProgressWithLabel  /> */}
                                                <Spinner animation="border" role="status">
                                                </Spinner>
                                            </div>
                                        )}
                                        <img className="img-fluid img-card" src={`https://photographer-api.codezilla.io/${item.image_url}`} alt={`Image ${index}`}
                                            onLoad={handleImageLoad} />

                                        <div className="delete-icon" onClick={() => {
                                            setSelectedImageId(item._id)
                                            setDeleteConfirmation(true);
                                        }}>
                                            <IoIosCloseCircleOutline className='icon-close' />
                                        </div>
                                    </div>
                                    <div className='img-heading'>  <p className='pt-3'>{item?.image_name}</p> </div>
                                </div>


                            </div>


                        ))}

                    </div>

                </div>

            </div>

            {deleteConfirmation && (
                <PopupModel
                    className="Popup"
                    ApiName={deletePhotos}
                    popUpFunction={setDeleteConfirmation}
                    title="Confirmation"
                    paragraph="Are you sure you want to Delete this image?"
                />
            )}
        </>
    );
};

export default Index;