import Button from './buttons';
import Search from '../Input/Search';
import Table from '../Input/Table';
import Input from '../Input'
import Select from './Select';


const atoms = {
    Button,
    Search,
    Table,
    Input,
    Select
}
export default atoms