import auth from './auth'
import dashboard from './dashboard'
import ManageClients from './manageclients'
import ManageEvents from './manageevents'
import ManagePhotos from './managephotos'

const service:any = {
  auth,
 dashboard,
 ManageEvents,
 ManageClients,
 ManagePhotos,
 
}
export default service
