import React from 'react'
import './sidebar.scss'
import Images from '../../assets/images/p-logo6.png';
import { BsPersonCheck } from "react-icons/bs";
import { TbCalendarStar } from "react-icons/tb";
import { RxDashboard } from 'react-icons/rx'
import { FiUpload } from 'react-icons/fi'


import { NavLink, } from 'react-router-dom';
// const { Image } = atoms
const SideBar = () => {
  // const navigate = useNavigate()
  return (
    <div col-md-4>
      <div className="s-layout nav-scroll">
        {/* Sidebar */}
        <div className="s-layout__sidebar">
          <a className="s-sidebar__trigger" href="#0">
            <i className="fa fa-bars" />
          </a>
          <nav className="s-sidebar__nav">
            <ul className='list-unstyled'>

              <div className="s-sidebar__nav-link d-flex align-items-center mt-3 ">
                <img src={Images} alt="Photographer Logo" className='img-fluid' />
                <h5 className='mb-0 person-p'>Photographer</h5>
              </div>
              <li className='mt-3 cursor nav-side px-3 mt-5' >
                <NavLink to="/mydashboard" className="s-sidebar__nav-link d-flex align-items-center mt-3 gap-3 p-lg-4 px-3 ">
                  {/* <CgWebsite className='fs-22' /> */}
                  <RxDashboard className='fs-22 d-board person-p' />
                  <h6 className='mb-0 d-head'>Dashboard</h6>
                </NavLink>
              </li>
              <li className='mt-4 cursor nav-side px-3' >
                <NavLink to="/manageclients" className="s-sidebar__nav-link d-flex align-items-center px-3 p-lg-4 gap-3">
                  <BsPersonCheck className='flex-lg-shrink-0 person-p' />

                  <h6 className='mb-0 m-style'>Manage Clients</h6>
                </NavLink>
              </li>
              <li className='mt-4 cursor nav-side px-3' >
                <NavLink to="/manageevents" className="s-sidebar__nav-link d-flex align-items-center px-3 p-lg-4 gap-3">
                  <TbCalendarStar className='flex-lg-shrink-0 person-p' />

                  <h6 className='mb-0 m-style'>Manage Events</h6>
                </NavLink>
              </li>
              {/* <li className='mt-4 cursor nav-side px-3' >
                <NavLink to="/uploadphotos" className="s-sidebar__nav-link d-flex align-items-center px-3 p-lg-4 gap-3 ">
                  <FiUpload className='flex-lg-shrink-0 person-p' />
               
                  <h6 className='mb-0 m-style'>Upload Photos</h6>
                </NavLink>
              </li> */}


            </ul>
          </nav>
        </div>
      </div>

    </div>

  )
}

export default SideBar