import React from 'react'
// import Images from '../../assets/Images';
// import { IoIosAddCircleOutline } from "react-icons/io";
// import Button from '../../components/atoms/Button'
import './topbar.scss'
const TopBar = (props: any) => {
    return (
        
        <div className="row mb-4">
            <div className='col-12 d-flex justify-content-between align-items-center'>
                <h1 className='mb-0'></h1>
                    <button
                        className='btn-add fs-16 p-2 px-4 align-items-center mb-0 text-wh'
                        onClick={props.onClick}>
                              <img src='' alt="" className='fs-30 me-2 mb-1'/>
                            {/* <IoIosAddCircleOutline className='fs-30 me-2'/> */}
                            {props.btnText}</button>
                </div>
            </div>
        
    )
}

export default TopBar