import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { manageclients } = Dictionary

const ManageClients = {
  view: async (paramsObj:any) => {
    console.log("paramsObj", paramsObj)
    return axios.get(BASE_URL + manageclients.clients(),{ params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + manageclients.addClients(), data)
  },
  status: async (id: string) => {
    return axios.put(BASE_URL + manageclients.ClientsById(id))
  },
  statusTwo: async (id: string) => {
    return axios.put(BASE_URL + manageclients.statusById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + manageclients.ClientsById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + manageclients.ClientsById(id))
  },

}
export default ManageClients
