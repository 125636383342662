import React from "react";
import Pages from '../pages/App/index'
import Auth from "../pages/Auth/lindex"
import { Navigate } from "react-router-dom";
import AddClients from "../pages/AddClients";
import AddEvents from '../pages/AddEvents'
import UploadPhotos from "../pages/UploadPhotos";
import ViewPhotos from "../pages/ViewPhotos";
import AddPhotos from "../pages/AddPhotos";


const { MyDashboard ,App, ManageEvents, ManageClients} = Pages
const { Login,ForgotPassword,ResetPassword } = Auth
const privateRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />, },
    { path: '/mydashboard', component: <MyDashboard /> },
    { path: '/app', component: <App /> },
    { path: '/manageevents', component: <ManageEvents /> },
    { path: '/manageclients', component: <ManageClients /> },
    { path: '/clients/add', component: <AddClients /> },
    { path: '/clients/edit/:id', component: <AddClients /> },
    { path: '/events/add', component: <AddEvents /> },
    { path: '/events/edit/:id', component: <AddEvents /> },
    { path: '/viewphotos/:id', component: <ViewPhotos /> },
    { path: '/viewphotos/:id/add', component: <AddPhotos /> },
    { path: '/uploadphotos', component: <UploadPhotos /> },
    { path: '/uploadphotos/add', component: <UploadPhotos /> },
  
   

]
const publicRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />, },
    { path: '/login', component: <Login /> },
    { path: '/forgotpassword', component: <ForgotPassword /> },
    { path: '/resetpassword', component: <ResetPassword /> },
    



]

export { privateRoutes, publicRoutes }