
import App from '../../molecules/App'
import MyDashboard from '../../pages/MyDashboard'
import ManageClients from '../ManageClients'
import ManageEvents from '../ManageEvents'
import Dashboard from './Dashboard'
// import ManagePhotographer from './Managephotographer'


const Pages={
    Dashboard,
//    ManagePhotographer,
   App,
   MyDashboard,
   ManageEvents,
   ManageClients
}
export default Pages
