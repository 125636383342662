import React, { useEffect, useState } from 'react'
import service from '../../api/services'
import organisms from '../../organism'
const { MyDashboard } = organisms
const Index = () => {
    const [manageEvents, setManageEvents] = useState()
    const [photoList, setPhotoList] = useState();



    const getPhotoList = async () => {
        try {
            let response = await service.dashboard.view()
            console.log('dashboard data admin', response)
            setManageEvents(response?.data)
            setPhotoList(response?.data?.count)

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        getPhotoList()

    }, [])

    return (
        <>
        <>{console.log("managePhotosmanagePhotos",manageEvents)}</>
            <MyDashboard
                managePhotos={manageEvents}
            />
        </>



    )

}

export default Index


