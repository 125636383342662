import React, { useState } from 'react'
import Wrapper from '../../layout/Wrapper/wrapper'
import CommonCard from '../../layout/Card/CommonCard/commoncard'
import molecules from '../../molecules'
import atoms from '../../components/atoms'
import { TbCalendarStar } from 'react-icons/tb'
import { RxDashboard} from 'react-icons/rx'
import{MdOutlinePhotoLibrary} from 'react-icons/md'
import './index.scss'
import TopBar from '../../layout/Topbar/topbar'
import { NavLink } from 'reactstrap'
import { useNavigate } from 'react-router-dom';

const { Button } = atoms





const Index = ({ managePhotos,photoList,dashboard,  }: any) => {
  // const [dashboard_Data, setDashboard_Data] = useState<any>([])
  const navigate = useNavigate();
  console.log("dashboard", dashboard)
  return (
    <>
      <Wrapper>
       <div className="card d-flex"> 

        <div className='col-md-6 d-flex mt-5 mx-5'>
        <div className="card-body-first pt-3 px-5"onClick={() => navigate('/manageclients')}>
              <MdOutlinePhotoLibrary className='person-l' />
          
            {/* <h4 className='card_font'>Manage photographer</h4> */}
            <p className='h-para'>Manage Clients</p>
            <div className='mb-3 h-num'> <h3> {managePhotos?.total_clients}</h3> </div>
          </div>
          <div className={"card-body-second pt-3 px-5 mx-5"} onClick={() => navigate('/manageevents')}>
              <TbCalendarStar className='person-l' />
            <NavLink>
            {/* <h4 className='card_font'>Manage photographer</h4> */}
            <>
            {console.log("managePhotos",managePhotos)}
            </>
            <p className='h-para'>Manage Events</p>
            <div className='mb-3 h-num'> <h3> {managePhotos?.total_events}</h3> </div>
            </NavLink>
          </div>

         
          {/* <CommonCard classNames={"card-body-thrd"}>
            <Button className='card_btn_thrd' >
              <BiUser className='icon_btn' />
            </Button>

            <h4 className='card_font'>Manage events</h4>
            <h1></h1>
            <div className='mb-3'>last month</div>
          </CommonCard> */}
        </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Index