import React, { useState } from "react";
// import { FiEye } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import constant from '../../constants/constant'
import atoms from '../../components/atoms'
import './client.scss'
import SelectTop from "../../layout/SelectTop/SelectTop";
const { Table, } = atoms

const Index = ({ setLimitPerPage, setCurrentPage, clientList, }: any) => {
    const navigate = useNavigate()
    console.log("client++++", clientList)

    let startIndex = (clientList?.currentPage - 1)*clientList?.limit
    const columns = [
        {
            name: 'S.No',
            selector: (row: any, index: number) => (startIndex +  index + 1).toString().padStart(2, '0'), width: "50px",
        },
        {
            name: 'Client Name',
            selector: (row: any) => row?.name, minWidth: "10px"
        },
        {
            name: 'Address',
            selector: (row: any) => row?.address,
        },
        {
            name: 'Phone Number',
            selector: (row: any) => row?.mobile,
        },
     
        {
            name: 'Email',
            selector: (row: any) => row?.email,
        },
        {
            name: 'Total Submission',
            selector: (row: any) => row?.total_submission,
        },
        {
            name: 'Action',
            selector: (row: any) => row.action,
        },
    ];
    
    return (      
        <div>
            <div>
      
            <>{console.log("clientList?.limitclientList?.limit",clientList)}</>
                <Table
                    pagination={true}
                    paginationTotalRows={clientList?.count}
                    setLimitPerPage={(e: number) => setLimitPerPage(e)}
                    setCurrentPage={(e: number) => setCurrentPage(e)}
                    columns={columns}
                    data={clientList?.data && clientList?.data
                        ?.map((item: any, index: number) => {
                            return ({
                                ...item,
                                status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                isActive: item.isActive ? (
                                    <div className="background-light-green border-product-radius">
                                        {/* <p className="color-green m-1 p-1">submitted</p> */}
                                    </div>) : <div className="background-light-red border-product-radius">
                                    {/* <p className="color-red m-1 p-1">{constant.STATUS.INACTIVE}</p> */}
                                </div>,
                                action:

                                    <> <div className='table-data-width'>
                                        <button
                                            className="edit-btn pe-4 px-3 "
                                            onClick={() => navigate(`/clients/edit/${item._id}`)}
                                        >
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </button>
                                  
                                    </div>
                                    </>




                                // ========================================================
                                // ...item,
                                // status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                // action:
                                //     <Link
                                //         to={`/contractors/edit/${item.id}`}
                                //     >
                                //         <Button className=" btnEdit table-edit-button fs-12">
                                //             <div className='d-flex align-items-center'>
                                //                 <FiEye />
                                //                 <span className='ms-2 mb-0'>View</span>
                                //             </div>
                                //         </Button>
                                //     </Link>





                            })
                        })
                    }
                />
            </div>
        </div>
        
      
    )
}

export default Index