import React, { useEffect, useState } from 'react'

// import atoms from '../../components/atoms'
import service from '../../api/services'
import organisms from '../../organism'
// const { Table, Button,  } = atoms
const { ManageEvents } = organisms
const Index = () => {
  const [eventsList, setEventsList] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [search, setSearch] = useState('');
  

  const getEventsList = async () => {
    try {
      let response = await service.ManageEvents.view({ "page": currentPage, "search": search, "limit": limitPerPage })
      console.log("responseEvents",response);
      if (response?.status === 200) {
        setEventsList(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    getEventsList()
  }, [currentPage,search,limitPerPage])

  // const handleStatus = async (id: string) => {
  //   try {
  //     let response = await service.contractor.status(id);
  //     console.log('hiii status response',response)
  //     if (response.status === 200) {
  //       // addToast('Status updated successfully', {
  //       //   appearance: 'success',
  //       //   autoDismiss: true,
  //       //   autoDismissTimeout: 3000,
  //       // });
  //       getContactorList()
  //     }
  //   } catch (error) {

  //   }
  // }
  return (
    <>
      <ManageEvents 
       setLimitPerPage={setLimitPerPage}
       setCurrentPage={setCurrentPage}
      eventsList={eventsList}
       setSearch={setSearch}
      //  currentPage={currentPage}
      />
    </>
  )
}

export default Index