import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs'
import * as Yup from 'yup';
import { Button, Spinner } from 'reactstrap';
import service from '../../api/services';
// import PopupModel from '../../components/Common/Popup';
import './upload.scss'
import { useParams } from 'react-router-dom';
const Index = ({ uploadList }: any) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageUrl, setImageUrl] = useState<any>([]);
    const [imageArr, setImageArr] = useState<any>([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState('');
    const params = useParams()
    const chooseFileToUpload = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const _handleImageChange = (e: any) => {
        if (e.target.files.length > 0) {
            let image_Url = URL.createObjectURL(e.target.files[0]);
            setImagePreview(image_Url);
            setImageUrl(e.target.files);
            console.log("e.target.files", e.target.files);
        }

    };

    const validationSchema = Yup.object({
        carousel: Yup.string().required('Please select photo(s)'),
    });



    const _handleSubmit = async () => {
        const formData = new FormData();
        console.log("Image URL", imageUrl.length, imageUrl[0])
        for (let i = 0; i < imageUrl.length; i++) {
            formData.append("photos", imageUrl[i]);
        }
        try {
            let response: any = await service.UploadPhotos.add(params.id, formData)
            console.log("Upload photos", response)
            getPhotos()
        } catch (error) {

        }
    };


    const getPhotos = async () => {

        try {
            let response: any = await service.UploadPhotos.view(params.id)
            console.log("Upload photos", response)
            setImageArr(response.data.data)
        } catch (error) {

        }
    };


    useEffect(() => {
        // Implement your API call to get images here
    }, []);

    useEffect(() => {
        setImageUrl(imageUrl);
        setImagePreview('');
    }, [imageUrl]);



    return (
        <>
            <div className="my-0 py-0">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumb breadcrumbItem="Upload Images" />
                        </div>
                    </div>
                    <div className="col-11">
                        <div className="row">
                            <div className="col-4">
                                <div style={{ marginTop: '24px' }}>
                                    <div>
                                        <div>
                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept="image/*"
                                                style={{ display: "none" }}

                                            />
                                        </div>
                                        <div className="avatarPreview cursor-pointer" onClick={chooseFileToUpload}>
                                            <div
                                                id="imagePreview"
                                                style={{
                                                    backgroundImage: `url(${imagePreview})`,
                                                }}
                                                className={`backgroundimage upload-image ${imagePreview && 'addcustomimage'}`}
                                            >
                                                <p className="text-primary text-center">Select Image</p>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        name="carousel"
                                        type="file"
                                        ref={inputFileRef}
                                        accept="image/*"
                                        onChange={(e) => {  _handleImageChange(e)}}
                                        multiple
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <div className="text-center mt-1">
                                    <Button className="up-btn" disabled={imageUrl ? false : true} onClick={_handleSubmit}>
                                        Upload
                                    </Button>
                                </div>
                            </div>
                          
                            {imageArr.map((item: any, index: number) => (
                        
                                <div key={item.id} className="col-4">
                                  
                                    <div className="imagePreview mt-4 mb-2">
                                        <img className="img-fluid" src={`https://photographer-api.codezilla.io/${item.image_url}`} alt={`Img ${index}`} 
                                        /> 
                                        <div className="delete-icon" onClick={() => {
                                            setSelectedImageId(item.id);
                                            setDeleteConfirmation(true);
                                        }}>
                                            <i className="dripicons-cross align-middle" />
                                        </div>
                                    </div>
                                    
                                </div>
                               
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* {deleteConfirmation && (
        <PopupModel
          ApiName={_handleDeleteClick}
          popUpFunction={setDeleteConfirmation}
          title="Confirmation"
          paragraph="Are you sure you want to Delete this image?"
        />
      )} */}
        </>
    );
};

export default Index;