import React from 'react';
import molecules from '../../molecules';
import Wrapper from '../../layout/Wrapper/wrapper';
import TopBar from '../../layout/Topbar/topbar';
import Card from '../../layout/Card/card';
import atoms from '../../components/atoms'
import { useNavigate,useParams } from 'react-router-dom';
// import { PiFunnelLight } from 'react-icons/pi';
import '../../molecules/ManageEvents/manage.scss';
// import './photos.scss'

const { UploadPhotos } = molecules;
const { Search } = atoms;

const Index = ({ uploadPhotos, uploadList, handleStatus}: any) => {
  const navigate = useNavigate();
  console.log("eventslists")

  return (
    <>
      <Wrapper>
        
        <Card>
          <div className='row'>
            <div className="col md-6 text-start d-flex"> 
            <TopBar
          // heading={'Upload Photos'}
          // btnText={'Upload Photos'}
          // onClick={() => navigate('/viewphotos/add')}
        />
            </div>
           </div>
        
          <UploadPhotos
            // setLimitPerPage={setLimitPerPage}
            // setCurrentPage={setCurrentPage}
            uploadPhotos={uploadPhotos}
            uploadList={uploadList}
            handleStatus={(id: string) => handleStatus(id)}
          />
        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
