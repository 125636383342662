import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { managephotos } = Dictionary

const ManagePhotos = {
  view: async (id:string,paramsObj:any) => {
    return axios.get(BASE_URL + managephotos.photos(id),{ params: paramsObj })
  },
  add: async (id:string, data: any) => {
    return axios.post(BASE_URL + managephotos.addPhotoss(id), data)
  },
  status: async (id: string) => {
    return axios.put(BASE_URL + managephotos.photosById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + managephotos.photosById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + managephotos.photosById(id))
  },
  deletePhotosById: async (id: string,data: any) => {
    // return axios.delete(BASE_URL + managephotos.deletePhotoss(id),data)
    return axios.post(BASE_URL + managephotos.deletePhotoss(id), data)
  },

}
export default ManagePhotos
